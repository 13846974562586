import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/badges',
    name: 'Badges',
    component: () => import(/* webpackChunkName: "badges" */ '../views/Badges.vue')
  },
  {
    path: '/registrations',
    name: 'About',
    component: () => import(/* webpackChunkName: "registrations" */ '../views/Registrations.vue')
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: () => import(/* webpackChunkName: "tickets" */ '../views/Tickets.vue')
  },
  {
    path: '*',
    name: 'Not Found',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
