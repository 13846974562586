<template>
  <div id="app">
    <router-view :globals="globals" />
  </div>
</template>

<script>
export default {
  name: 'App',
  async created() {
    // Override API endpoint
    if ( process.env.VUE_APP_ENDPOINT ) {
      this.globals.endpoint = process.env.VUE_APP_ENDPOINT;
    }
  },
  data() {
    return {
      globals: {
        endpoint: 'https://api.magicconvention.com',
      }
    }
  }
}

</script>

<style>
@font-face {
  font-family: 'Alegraya';
  src: url('./assets/fonts/Alegreya-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Alegraya Sans Bold';
  src: url('./assets/fonts/AlegreyaSans-Bold.ttf');
}
@font-face {
  font-family: 'Alegraya Sans Italic';
  src: url('./assets/fonts/AlegreyaSans-Italic.ttf');
}
@font-face {
  font-family: 'Alegraya Sans';
  src: url('./assets/fonts/AlegreyaSans-Regular.ttf');
}


body {
  font-family: 'Alegraya Sans';
  text-align: center;
}

h1, h2, h3, h4, h5 {
  font-family: 'Alegraya';
  letter-spacing: 0.05em;
}
h1 {
  font-size: 1.8em;
}
h2 {
  font-size: 1.2em;
}

</style>
